<template>
  <footer class="footer px-5 d-flex justify-content-between">
    <div class="copyright">Copyright &copy; {{new Date().getFullYear()}} <span class="theme-font-bold">citizen</span>portal</div>
    <ul id="GS-CTZB-FTR" class="nav flex-column flex-sm-row footer-links">
      <li v-for="(footerHelp, index) in footerHelpUrlsList" :key="index">
        <a target="_blank" :href="footerHelp.url">{{footerHelp.name}}</a>
      </li>
    </ul>
  </footer>
</template>
<script>
import { FOOTER_HELP_URLS_LIST } from '../../utilities/constants'
export default {
  name: 'Footer',
  data () {
    return {
      footerHelpUrlsList: FOOTER_HELP_URLS_LIST
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
