<template>
  <header>
    <b-navbar class="px-5" toggleable="sm">
      <b-navbar-brand to="/dashboard"><strong>citizen</strong>portal</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav id="GS-CTZB-NAV" class="ml-5" v-if="userRole != 'Unauthorized'">
          <b-nav-item v-if="userRole == 'SysAdmin'" to="/cities">Cities</b-nav-item>
          <b-nav-item to="/widgets">Widgets</b-nav-item>
          <b-nav-item to="/users" v-if="isEnabledUsers">Users</b-nav-item>
          <b-nav-item to="/settings">Settings</b-nav-item>
          <b-nav-item to="/languages" v-if="isMultilingualEnabled" id="languages-menu-link">Languages</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto align-items-center right-items">
          <b-nav-item-dropdown class="help-dropdown" no-caret menu-class="help-info-dropdown" right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <BIconQuestionCircle class="pt-1 help-icon" />
            </template>
            <b-dropdown-item
              class="theme-font-medium"
              :href="help.url"
              :title="help.helpText"
              target="_blank"
              v-for="(help, index) in helpInfoList"
              :key="index"
              >{{ help.name }}</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>
<script>
import { mapState } from 'vuex'
import { HELP_INFO_LIST } from '../../utilities/constants'
import { BIconQuestionCircle } from 'bootstrap-vue'

export default {
  name: 'Header',
  data() {
    return {
      helpInfoList: HELP_INFO_LIST,
    }
  },
  computed: {
    isMultilingualEnabled() {
      return this.userRole === 'SysAdmin' ? true : this.selectedTenant && this.selectedTenant.length ? this.selectedTenant[0].isMultilingualEnabled : false
    },
    ...mapState({
      userRole: (state) => state.common.userRole,
      selectedTenant: (state) => state.common.selectedTenant,
      isEnabledUsers: (state) => state.common.isEnabledUsers,
    }),
  },
  components: {
    BIconQuestionCircle,
  },
  methods: {
    signOut() {
      this.$store.dispatch('common/signOut')
    },
  },
}
</script>
<style lang="scss" scoped></style>
